import React, { Component } from "react";
import styled from "styled-components";
import { isSubmissionOpen } from "../../utils/impactFestival";

const FestButton = styled.a`
  display: inline-flex;
  align-items: center;
  font-size: 20px !important;
  line-height: 1 !important;
  min-height: 56px !important;
`;

class LandingSection extends Component {
  render() {
    return (
      <>
        <section className="vertical-scrolling animated-row">
          <div className="section-inner">
            <div className="welcome-box  mt-5">
              <span
                className="welcome-first animate f-weight-600"
                data-animate="fadeInUp"
              >
                Welcome to
              </span>
              <h1 className="welcome-title animate" data-animate="fadeInUp">
                AI Facilitator Community
              </h1>
              <p className="animate f-weight-600" data-animate="fadeInUp">
                AI Facilitator Community (AIFC) is an online community
                connecting teachers, facilitators, coaches, educators, etc. AIFC
                aims at providing opportunities for facilitators for
                collaboration, sharing best practices, and professional
                development by upgrading their AI skillset. In turn, this
                platform shall enable the facilitators in empowering the
                students to create AI-enabled social impact solutions and
                democratizing AI awareness.
              </p>
              <div className="d-flex flex-column justify-content-center flex-lg-row align-items-center align-items-lg-start">
                <a
                  href="/about"
                  className="btn btn-light animate mt-lg-0"
                  data-animate="fadeInUp"
                >
                  <span>About</span>
                </a>
                <button
                  className="btn btn-light animate mt-3 mt-lg-0 ml-lg-3"
                  data-animate="fadeInUp"
                  onClick={() => {
                    this.props.showVideo(true);
                  }}
                >
                  <i className="fa fa-play"></i>
                  <span>Intro Video</span>
                </button>
                {isSubmissionOpen() && (
                  <FestButton
                    href="/india-ai-impact-festival-2024"
                    className="btn btn-light animate mt-3 mt-lg-0 ml-lg-3"
                    data-animate="fadeInUp"
                  >
                    <span>India AI Impact Festival 2024</span>
                  </FestButton>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LandingSection;
